import { meta } from "../_module/meta";

// import { TimelineMax } from "gsap";
// import { windowSize } from "./../_module/windowSize";

export const News_low = {
  /**
   * vals
   */
  id: "",
  pageData: "",
  /**
   * funcs
   */
  init: () => {
    News_low.paramCheck();
  },
  paramCheck: () => {
    let queryString = window.location.search;
    let queryObject = new Object();
    if (queryString) {
      queryString = queryString.substring(1);
      let parameters = queryString.split("&");
      for (let i = 0; i < parameters.length; i++) {
        let element = parameters[i].split("=");
        let paramName = decodeURIComponent(element[0]);
        let paramValue = decodeURIComponent(element[1]);
        queryObject[paramName] = paramValue;
      }
      for (let k of Object.keys(queryObject)) {
        if (k == "id") {
          if (
            queryObject[k] != "" &&
            queryObject[k] != undefined &&
            queryObject[k] != null
          ) {
            News_low.id = queryObject[k];
            News_low.getData();
          } else {
            News_low.toNews();
          }
        } else {
          News_low.toNews();
        }
      }
    }
  },
  setPage: () => {
    // meta

    meta.init(News_low.pageData);

    $(".footer-pan li")
      .last()
      .find("a")
      .html(News_low.pageData["title"]);

    for (var key in News_low.pageData) {
      if (News_low.pageData[key] != "" && News_low.pageData[key] != null) {
        if (key == "image") {
          $(".set-" + key).attr("src", News_low.pageData[key]);
          $(".set-" + key).attr("alt", News_low.pageData["title"]);
        } else if (key == "date") {
          let d = News_low.pageData[key];
          $(".set-" + key).html(d.replace(/-/g, "."));
        } else {
          $(".set-" + key).html(News_low.pageData[key]);
        }
      } else {
        $(".set-" + key).remove();
      }
    }
  },
  toNews: () => {
    location.href = "/news/";
  },
  getData: () => {
    let isID = false;
    API_WEB.M2("news", 1, "").then(data => {
      for (let i = 0, li = data.length; i < li; i++) {
        if (data[i].id == News_low.id) {
          isID = true;
          // Publishing_low.pageData = data[i];
          // Publishing_low.setPage();
          News_low.pageData = data[i];
          News_low.setPage();
          break;
        }
      }
      if (isID == false) {
        News_low.toNews();
      }
    });
  }
};
