// window.jQuery = window.$ = require('jquery');
// import { TweenMax } from "gsap";

import "./_libs/jquery.easings.min";
import { ua } from "./_module/ua";

import { global } from "./_module/global";
import API_WEB from "./_module/api";
// import {inview} from './_module/inviewhandle';
// import { modal } from "./_module/modal";
import { menu } from "./_module/menu";
import { windowSize } from "./_module/windowSize";
// import { Scroll } from "./_module/scroll";
// import { intro } from "./_module/intro";
// // import { video } from "./_module/video";

// import { MainTitle } from "./_module/maintitle";
// import { Ripple } from "./_module/ripples";

// pages
import { Top } from "./_page/index";
import { Taiken } from "./_page/taiken";
import { Taiken_low } from "./_page/taiken_low";
import { News } from "./_page/news";
import { News_low } from "./_page/news_low";
import { Museum } from "./_page/museum";
import { Museum_low } from "./_page/museum_low";
import { Publishing } from "./_page/publishing";
import { Publishing_low } from "./_page/publishing_low";
import { History_low } from "./_page/history_low";
import { Search } from "./_page/search";
import { Search_type } from "./_page/searchtype";
import { Search_detail } from "./_page/searchdetail";

// import slick from "slick-carousel";
import "./../style/app.scss";

class Index {
  constructor() {
    this.body = $("body");
    this.window = $(window);
    this.document = $(document);

    this.isMOBILE = "MOBILE";
    this.isIE_EDGE = "IE_EDGE";
    this.isPC = "PC";
    this.currentPage = "top";
    this.currentWindowWidth = windowSize.clientSize().W;
    this.isAnchor = true;

    this.directory();
  }

  directory() {
    menu.init();
    // this.currentPage = $("div").hasClass("Top") ? "Top" : "Detail";
    let page = $(".page").data("page");
    global.page = page;
    switch (page) {
      case "Top":
        Top.init();
        break;
      case "Museum":
        Museum.init();
        break;
      case "Museum-low":
        Museum_low.init();
        break;
      case "Taiken":
        Taiken.init();
        break;
      case "Taiken-low":
        Taiken_low.init();
        break;
      case "History-low":
        History_low.init();
        break;
      case "Search":
        Search_type.init();
        break;
      case "News":
        News.init();
        break;
      case "Publishing":
        Publishing.init();
        break;
      case "Publishing-low":
        Publishing_low.init();
        break;
      case "News-low":
        News_low.init();
        break;
      case "Search-all":
        Search.init();
        break;
      case "Search-detail":
        Search_detail.init();
        break;

      default:
    }
    this.init();
  }

  init() {
    this.anchorToTop();
    this.body.addClass(ua());

    this.getServerTime();
  }

  getServerTime() {
    let request = new XMLHttpRequest();
    request.open("HEAD", window.location.href, true);
    request.send();
    request.onreadystatechange = function() {
      if (this.readyState === 4) {
        let serverDate = new Date(request.getResponseHeader("Date"));
        $(".footer-copy span").html(serverDate.getFullYear() + " ");
      }
    };
  }

  resize() {
    let setTimeoutId = null;
    window.addEventListener("resize", e => {
      if (setTimeoutId) clearTimeout(setTimeoutId);
      setTimeoutId = setTimeout(() => {
        // this.device();
        // Scroll.stackBlockPosition();
        setTimeoutId = null;
      }, 1000);
    });
  }

  anchorToTop() {
    $(".footer-pageTop > a").on("click", function(event) {
      event.preventDefault();
      let href = $(this).attr("href");
      jQuery("html,body")
        .stop()
        .animate(
          {
            scrollTop: $(href).offset().top + 20
          },
          1300,
          "easeInOutExpo"
        );
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  new Index();
});

window.onpageshow = function(event) {
  if (event.persisted) {
    // window.location.reload();
    if (
      document.getElementById("Search-detail") != null ||
      document.getElementById("Taiken-low") != null ||
      document.getElementById("Top") != null
    ) {
      window.location.reload();
    }
  }
};
// window.onpageshow=function(event) {
//   if(event.persisted) window.location.reload();
// };
// window.addEventListener("DOMContentLoaded", () => {
//   new Whale()
// });
