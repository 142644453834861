export const windowSize = {
  breakPoint: 768,
  clientSize: () => {
    let w=window.innerWidth;
    let h=window.innerHeight;
    return {
      W: w,
      H: h
    }
  },
  device: () => {
    let isPC = (windowSize.clientSize().W > windowSize.breakPoint) ? true : false;
    return isPC;
  }
};