import { LowPageScroll } from "./../_module/lowpagescroll";
import { meta } from "./../_module/meta";
import "./../_module/loadYoutubeResource";
import { Ytb_Ready } from "./../_module/enbedYoutube";

export const Taiken_low = {
  /**
   * vals
   */
  id: "",
  num: "",
  pageData: "",
  linkData: [],
  /**
   * funcs
   */
  init: () => {
    LowPageScroll.init();
    Taiken_low.paramCheck();
  },
  getData: () => {
    API_WEB.M3("taiken", 1, Taiken_low.id).then(data => {
      if (data == "" || data.length == 0) {
        Taiken_low.toTaiken();
      }
      Taiken_low.pageData = data[0];
      Taiken_low.setData();
    });
    API_WEB.M2("taiken", 1).then(data => {
      data = data.reverse();
      for (let i = 0, li = data.length; i < li; i++) {
        if (data[i].id != Taiken_low.id) {
          Taiken_low.linkData.push(data[i]);
        }
      }
      if (Taiken_low.linkData != "") {
        Taiken_low.setLink();
      }
    });
  },
  setLink: () => {
    let len = Taiken_low.linkData.length >= 4 ? 4 : Taiken_low.linkData.length;
    for (let i = 0, li = len; i < li; i++) {
      let taiken =
        "" +
        "<li>" +
        '<a href="/taiken/detail/?id=' +
        Taiken_low.linkData[i].id +
        "&num=" +
        Taiken_low.linkData[i].index +
        // (i + 1) +
        '">' +
        '<div class="m-number-label">' +
        '<div class="number-labelInner">' +
        "<p>" +
        "#" +
        "<span>" +
        // (i + 1) +
        Taiken_low.linkData[i].index +
        "</span>" +
        "</p>" +
        "</div>" +
        '<div class="number-labelBg"></div>' +
        "</div>" +
        '<div class="record-links-itemThumb">' +
        '<span style="background-image: url(' +
        Taiken_low.linkData[i].image +
        ');"></span>' +
        "</div>" +
        '<div class="record-links-itemTxt section-item-ttl">' +
        "<span>" +
        Taiken_low.linkData[i].name +
        "</span>" +
        '<div class="m-arrCirc"><div class="arrCirc-ico"></div></div>' +
        "</div>" +
        '<div class="m-txtBg bottom-txtBg"></div>' +
        "</a>" +
        "</li>" +
        "";
      $(".record-list-taikenBottom").append(taiken);
      // $(taiken).prependTo(".record-list-taikenBottom");
    }
  },
  setData: () => {
    let m = {
      title: Taiken_low.pageData["title"] + " | 須坂のまるごと博物館",
      desc: Taiken_low.pageData["desc"]
    };
    meta.init(m);

    $(".footer-pan li")
      .last()
      .find("a")
      .html(Taiken_low.pageData["title"]);

    $(".mv-num").html("#" + Taiken_low.num);
    $(".mv-img").css(
      "background-image",
      "url(" + Taiken_low.pageData["image_detail"] + ")"
    );

    for (var key in Taiken_low.pageData) {
      $(".set-" + key).html(Taiken_low.pageData[key]);

      if (key == "cont01") {
        for (let i = 0, li = Taiken_low.pageData[key].length; i < li; i++) {
          let _img = "";
          if (
            Taiken_low.pageData[key][i]["image"] != null &&
            Taiken_low.pageData[key][i]["image"] != ""
          ) {
            _img =
              '<div class="record-block-thumb record-block-mb3">' +
              '<img src="' +
              Taiken_low.pageData[key][i]["image"] +
              '" alt="' +
              Taiken_low.pageData[key][i]["alt"] +
              '">' +
              "</div>";
          }
          let txt =
            "" +
            '<div class="record-block-cont1-Item">' +
            '<h3 class="record-block-head record-block-mb2">' +
            Taiken_low.pageData[key][i]["title"] +
            "</h3>" +
            '<p class="record-block-body record-block-mb2">' +
            Taiken_low.pageData[key][i]["text"] +
            "</p>" +
            _img +
            "</div>" +
            "";
          $(".record-block-cont1").append(txt);
        }
      }
      if (key == "cont02") {
        let videoId = [];
        // let dummyID = ["8r29Wfl0vLs", "VXdNYB2uQ7s"];
        for (let i = 0, li = Taiken_low.pageData[key].length; i < li; i++) {
          if (
            Taiken_low.pageData[key]["title"] != "" &&
            Taiken_low.pageData[key][i]["url"] != ""
          ) {
            let movie =
              "" +
              '<div class="record-block-cont2-Item">' +
              '<h3 class="record-block-head record-block-mb1">' +
              Taiken_low.pageData[key][i]["title"] +
              // "タイトル１" +
              "</h3>" +
              '<div class="record-block-thumb record-block-mb3 record-block-video">' +
              '<div id="' +
              Taiken_low.pageData[key][i]["url"] +
              // dummyID[i] +
              '">' +
              "</div>" +
              "</div>" +
              "</div>" +
              "";
            $(".record-block-cont2").append(movie);

            videoId.push(Taiken_low.pageData[key][i]["url"]);
          }
          // videoId.push(dummyID[i]);
        }
        if (videoId.length != 0) {
          Ytb_Ready.init(videoId);
        }
      }

      if (key == "place") {
        if (
          Taiken_low.pageData[key] != "" ||
          Taiken_low.pageData[key].length != 0
        ) {
          // name image address latitude longitude text url
          for (let i = 0, li = Taiken_low.pageData[key].length; i < li; i++) {
            let _name = "";
            let _image = "";
            let _address = "";
            let _latitude = "";
            let _longitude = "";
            let _text = "";
            let _url = "";
            for (let key2 in Taiken_low.pageData[key][i]) {
              // console.log(Taiken_low.pageData[key][i][key2]);
              _name = Taiken_low.pageData[key][i]["name"];
              _image = Taiken_low.pageData[key][i]["image"];
              _address = Taiken_low.pageData[key][i]["address"];
              _latitude = Taiken_low.pageData[key][i]["latitude"];
              _longitude = Taiken_low.pageData[key][i]["longitude"];
              _text = Taiken_low.pageData[key][i]["text"];
              _url = Taiken_low.pageData[key][i]["url"];
            }

            let ll = "q=" + _latitude + "," + _longitude;

            let relPlace =
              "" +
              '<div class="record-bottom-box">' +
              '<div class="record-bottom-place">' +
              '<div class="record-bottom-placeTxt">' +
              '<h4 class="record-bottom-placeTtl">' +
              '<a href="' +
              _url +
              '" target="_blank" class="set-place-name is-defaultHover">' +
              _name +
              "</a>" +
              "</h4>" +
              '<p class="record-bottom-placeAdd set-place-address">' +
              _address +
              "</p>" +
              '<p class="record-bottom-placeBody set-place-text">' +
              _text +
              "</p>" +
              "</div>" +
              '<div class="record-bottom-placeImg">' +
              '<span class="set-place-image" style="background-image: url(' +
              _image +
              ');"></span>' +
              "</div>" +
              "</div>" +
              '<div class="record-bottom-map">' +
              "" +
              '<iframe src="https://maps.google.co.jp/maps?output=embed&t=m&' +
              ll +
              '"' +
              " width='600' height='450' frameborder='0' style='border:0' aria-hidden='false' tabindex='0' allowfullscreen=''" +
              ">" +
              "</div>" +
              "</div>" +
              "";
            $(".record-bottomInnerBox").append(relPlace);
          }
        } else {
          $(".record-bottom").remove();
        }
      }
    }
  },
  paramCheck: () => {
    let isID = false;
    let queryString = window.location.search;
    let queryObject = new Object();
    if (queryString) {
      queryString = queryString.substring(1);
      let parameters = queryString.split("&");
      for (let i = 0; i < parameters.length; i++) {
        let element = parameters[i].split("=");
        let paramName = decodeURIComponent(element[0]);
        let paramValue = decodeURIComponent(element[1]);
        queryObject[paramName] = paramValue;
      }
      for (let k of Object.keys(queryObject)) {
        if (k == "id") {
          if (
            queryObject[k] != "" &&
            queryObject[k] != undefined &&
            queryObject[k] != null
          ) {
            isID = true;
            Taiken_low.id = queryObject[k];
          } else {
            Taiken_low.toTaiken();
          }
        }
        if (k == "num") {
          Taiken_low.num = queryObject[k];
        }
      }

      if (isID == true) {
        Taiken_low.getData();
      } else {
        Taiken_low.toTaiken();
      }
    }
  },
  toTaiken: () => {
    location.href = "/taiken/";
  }
};
