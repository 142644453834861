import { windowSize } from "./../_module/windowSize";
import { Scroll } from "./../_module/scroll";

import slick from "slick-carousel";

export const Top = {
  /**
   * vals
   */
  currentSlide: 0,
  clientWidth: 0,
  mvSlide: [],
  isNavi: false,
  slickConfigDiscover: {
    dots: false,
    arrows: true,
    slidesToShow: 1,
    autoplay: false,
    speed: 650,
    centerMode: true,
    centerPadding: "28%",
    cssEase: "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
    draggable: true,
    // pauseOnHover: false,
    // focusOnSelect: true,
    responsive: [
      {
        breakpoint: 769,
        // centerMode: false,
        // infinite: false,
        // breakpoint: 850,
        settings: {
          centerPadding: "6%"
        }
      }
    ]
  },
  /**
   * funcs
   */
  init: () => {
    Top.resizeEvt();
    Top.scrollEvt();
    Top.clickEvt();

    Top.setMvArea();
    Top.setRecordArea();
    Top.setNewsArea();
    Top.setDiscoverArea();
  },
  setMvArea: () => {
    API_WEB.M2("top", 1, "").then(data => {
      for (let i = 0, li = data.length; i < li; i++) {
        let img = data[i].image;
        let slide =
          "" +
          '<div class="section-top-slide">' +
          '<a href="javascript:void(0);">' +
          // "<span class='section-top-slideBlur'><span></span></span>" +
          "<span class='section-top-slideImage' data-mvimg=" +
          img +
          " " +
          'style="background-image: url(' +
          img +
          ');">' +
          "</span>" +
          "</a>" +
          "</div>" +
          "";

        // $(".section-top-slider").append(slide);
        $(".section-top-slider").prepend(slide);

        let activeThumb =
          "" +
          "<li>" +
          '<a href="javascript:void(0);">' +
          '<div class="section-thumb-circ"></div>' +
          "<p>" +
          "<span>" +
          data[i].name +
          "</span>" +
          "</p>" +
          "</a>" +
          "</li>" +
          "";

        $(".section-thumb-active").prepend(activeThumb);
        // $(".section-thumb-active").append(activeThumb);
      }
      setTimeout(() => {
        $(".section-thumb-active li")
          .eq(0)
          .addClass("is-active");

        $("#Top").addClass("is-active");
        // $(".section-top-slider .slick-list .slick-track .slick-slide")
        //   .eq(Top.currentSlide)
        //   .find(".section-top-slideImage")
        //   .addClass("is-active");
        // $(".section-top-slider .slick-list .slick-track .slick-slide")
        //   .eq(Top.currentSlide)
        //   .find(".section-top-slideBlur")
        //   .addClass("is-active");
        Top.setMvSlick();
      }, 100);
    });
  },
  setRecordArea: () => {
    API_WEB.M2("taiken", 1, "").then(data => {
      data = data.reverse();
      for (let i = 0, li = data.length; i < li; i++) {
        // for (let i = data.length - 1; i >= 0; i--) {
        let l = "";
        if (i == 0) {
          // 体験記が増えたら l-large に
          l = "l-large";
          // l = "";
        }
        let taiken =
          "" +
          '<li class="' +
          l +
          '">' +
          '<a href="/taiken/detail/?id=' +
          data[i].id +
          "&num=" +
          // (i + 1) +
          data[i].index +
          // (data.length - i) +
          '">' +
          '<div class="m-number-label">' +
          '<div class="number-labelInner">' +
          "<p>" +
          "#" +
          "<span>" +
          // (i + 1) +
          // (data.length - i) +
          data[i].index +
          "</span>" +
          "</p>" +
          "</div>" +
          '<div class="number-labelBg"></div>' +
          "</div>" +
          '<div class="section-record-itemThumb">' +
          '<span style="background-image: url(' +
          data[i].image +
          ');"></span>' +
          "</div>" +
          '<div class="section-record-itemTxt section-item-ttl">' +
          "<span>" +
          data[i].name +
          "</span>" +
          '<div class="m-arrCirc"><div class="arrCirc-ico"></div></div>' +
          "</div>" +
          '<div class="m-txtBg bottom-txtBg"></div>' +
          "</a>" +
          "</li>" +
          "";

        // $(taiken).prependTo(".section-record-list");
        $(".section-record-list").append(taiken);
        // $(".section-record-list").prependTo(taiken);
      }
    });
  },
  setNewsArea: () => {
    API_WEB.M2("news", 1, "").then(data => {
      let len = data.length >= 3 ? 3 : data.length;
      for (let i = 0, li = len; i < li; i++) {
        let d = data[i].date;
        let link =
          data[i]["link"] != ""
            ? data[i]["link"]
            : "/news/detail/?id=" + data[i]["id"];
        let isBlank = data[i]["flg_samewindow"] != true ? "_blank" : "_self";
        let news =
          "" +
          "<li>" +
          '<a class="is-defaultHover" href="' +
          link +
          '" target="' +
          isBlank +
          '">' +
          '<p class="news-itemDate">' +
          d.replace(/-/g, ".") +
          "</p>" +
          '<p class="news-itemBody">' +
          data[i].title +
          "</p>" +
          "</a>" +
          "</li>" +
          "";
        $(".news-blockList").append(news);
      }
    });
  },
  setDiscoverArea: () => {
    API_WEB.M2("top", 2, "").then(data => {
      for (let i = 0, li = data.length; i < li; i++) {
        let pick =
          "" +
          '<div class="section-discover-item">' +
          '<a href="/search/item/?id=' +
          data[i].link +
          '">' +
          '<div class="m-number-label">' +
          '<div class="number-labelInner">' +
          "<p>" +
          "#" +
          "<span>" +
          (i + 1) +
          "</span>" +
          "</p>" +
          "</div>" +
          '<div class="number-labelBg"></div>' +
          "</div>" +
          '<div class="section-discover-itemThum">' +
          '<span style="background-image: url(' +
          data[i].image +
          ');"></span>' +
          "</div>" +
          '<h3 class="section-discover-itemTtl section-item-ttl">' +
          "<span>" +
          data[i].title +
          "</span>" +
          '<div class="m-arrCirc"><div class="arrCirc-ico"></div></div>' +
          "</h3>" +
          '<div class="m-txtBg bottom-txtBg"></div>' +
          "</a>" +
          "</div>" +
          "";
        $(".section-discover-list").append(pick);
      }

      Top.setDiscSlick();
    });
  },
  setMvSlick: () => {
    $(".section-top-slider")
      .not(".slick-initialized")
      .slick({
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        autoplaySpeed: 7000,
        autoplay: true,
        speed: 1000,
        centerMode: true,
        fade: true,
        cssEase: "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        pauseOnDotsHover: false,

        waitForAnimate: false,
        customPaging: function(slick, index) {
          let targetImage = slick.$slides
            .eq(index)
            .find(".section-top-slideImage")
            .data("mvimg");
          let html =
            "" +
            '<div class="dots-thumb" style="background-image:url(' +
            targetImage +
            ');">' +
            "</div>" +
            "";
          return html;
        }
      })
      .on("beforeChange", function(event, slick, currentSlide, nextSlide) {
        $(".section-thumb-active li").removeClass("is-active");
        $(".section-thumb-active li")
          .eq(nextSlide)
          .addClass("is-active");
        // setTimeout(() => {
        //   $(".section-top-slider").slick("slickPlay");
        // }, 100);
      })
      .on("afterChange", function(event, slick, currentSlide) {
        Top.currentSlide = currentSlide;

        // setTimeout(() => {
        //   $(".section-top-slider").slick("slickPlay");
        // }, 100);
      });
    Top.setMvHeightSp();
    // $(".section-top-slider").on("touchmove", function(
    //   event,
    //   slick,
    //   currentSlide,
    //   nextSlide
    // ) {
    //   $(".section-top-slider").slick("slickPlay");
    // });
  },
  setDiscSlick: () => {
    if (windowSize.device()) {
      $(".section-discover-list")
        .not(".slick-initialized")
        .slick(Top.slickConfigDiscover);
    } else {
      $(".section-discover-list.slick-initialized").slick("unslick");
    }
  },
  naviTrans: () => {
    if (windowSize.clientSize().H / 2 < Scroll.position()) {
      $("#l-header").addClass("is-active");
      $("#l-sideMenu").addClass("is-active");
      $(".l-sideMenu-bg").addClass("is-active");
    } else {
      $("#l-header").removeClass("is-active");
      $("#l-sideMenu").removeClass("is-active");
      $(".l-sideMenu-bg").removeClass("is-active");
    }
  },
  setMvHeightSp: () => {
    if (!windowSize.device()) {
      if (Top.clientWidth != windowSize.clientSize().W) {
        $(".section-top-slide a .section-top-slideImage").css(
          "height",
          windowSize.clientSize().H
        );
        Top.clientWidth = windowSize.clientSize().W;
      }
    }
  },
  resizeEvt: () => {
    let setTimeoutId = null;
    window.addEventListener("resize", e => {
      if (setTimeoutId) clearTimeout(setTimeoutId);
      setTimeoutId = setTimeout(() => {
        Top.setMvSlick();
        Top.setDiscSlick();
        Top.setMvHeightSp();
        setTimeoutId = null;
      }, 1000);
    });
  },
  clickEvt: () => {
    $(".searchBox-submit a").on("click", function() {
      Top.startSearch();
    });
    document.getElementById("search_form").onkeypress = e => {
      const key = e.keyCode || e.charCode || 0;
      if (key == 13) {
        e.preventDefault();
        Top.startSearch();
      }
    };
  },
  startSearch: () => {
    location.href = "/search/?" + Top.returnQuery();
  },
  returnQuery: () => {
    const word = document.getElementById("input_msg").value;
    const c1 = word.split(/\s/);
    let query = "word=" + c1;
    return query;
  },
  scrollEvt: () => {
    let setTimeoutId = null;
    window.addEventListener("scroll", e => {
      if (setTimeoutId) clearTimeout(setTimeoutId);
      setTimeoutId = setTimeout(() => {
        Top.naviTrans();
        setTimeoutId = null;
      }, 10);
    });
  }
};
