// import { TimelineMax } from 'gsap';
// import { global } from "./global";
// import { menu } from "./menu";
// import { windowSize } from "./windowSize";

export const LowPageScroll = {
  // duration: 1.1,
  // ease: "Expo.easeOut",
  // inview: $(".js-inview"),
  // block: $(".block"),
  // show: "is-show",
  active: "is-active",
  // posArray: [],
  // blockOffsetArray: [],
  // heightAdjustValue: 0.78,
  init: () => {
    LowPageScroll.scrollEvt();
    // if ($("div").hasClass("m-back")) {
    //   LowPageScroll.setPageBack();
    // }
  },
  setPageBack: () => {
    if (LowPageScroll.position() > 50) {
      $(".m-back").addClass(LowPageScroll.active);
    } else {
      $(".m-back").removeClass(LowPageScroll.active);
    }
  },
  scrollEvt: () => {
    let setTimeoutId = null;
    window.addEventListener("scroll", e => {
      if (setTimeoutId) clearTimeout(setTimeoutId);
      setTimeoutId = setTimeout(() => {
        LowPageScroll.setPageBack();
        setTimeoutId = null;
      }, 10);
    });
  },
  position: () => {
    return (
      document.documentElement.scrollTop ||
      document.body.parentNode.scrollTop ||
      document.body.scrollTop
    );
  }
};
