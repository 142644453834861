// import { TimelineMax } from "gsap";
// import { windowSize } from "./../_module/windowSize";

export const News = {
  /**
   * vals
   */
  year: [],
  pageData: "",
  latest: null,
  current: null,
  /**
   * funcs
   */
  init: () => {
    News.clickEvt();
    News.getData();
  },
  getData: () => {
    API_WEB.M2("news", 1, "").then(data => {
      News.pageData = data;
      News.setNews();
    });
  },
  setNews: () => {
    // let data = [
    //   {
    //     id: "2501",
    //     date: "2021-02-21",
    //     title:
    //       "2021 信濃に勢力を伸ばしてきた武田信玄と、北信濃に強い力を持っていた越後の上杉謙信との戦いに巻き込まれて行きました。戦国時代の須坂には、井上氏・高梨氏・須田氏などが国人領主として勢力を持ち",
    //     flg_samewindow: false
    //   },
    //   {
    //     id: "2501",
    //     date: "2021-02-01",
    //     title:
    //       "2021 信濃に勢力を伸ばしてきた武田信玄と、北信濃に強い力を持っていた越後の上杉謙信との戦いに巻き込まれて行きました。戦国時代の須坂には、井上氏・高梨氏・須田氏などが国人領主として勢力を持ち",
    //     flg_samewindow: false
    //   },
    //   {
    //     id: "2501",
    //     date: "2019-01-21",
    //     title:
    //       "2019 信濃に勢力を伸ばしてきた武田信玄と、北信濃に強い力を持っていた越後の上杉謙信との戦いに巻き込まれて行きました。戦国時代の須坂には、井上氏・高梨氏・須田氏などが国人領主として勢力を持ち",
    //     flg_samewindow: true
    //   },
    //   {
    //     id: "2501",
    //     date: "2019-01-21",
    //     title:
    //       "2019 信濃に勢力を伸ばしてきた武田信玄と、北信濃に強い力を持っていた越後の上杉謙信との戦いに巻き込まれて行きました。戦国時代の須坂には、井上氏・高梨氏・須田氏などが国人領主として勢力を持ち",
    //     flg_samewindow: true
    //   },
    //   {
    //     id: "2501",
    //     date: "2019-01-21",
    //     title:
    //       "2019 信濃に勢力を伸ばしてきた武田信玄と、北信濃に強い力を持っていた越後の上杉謙信との戦いに巻き込まれて行きました。戦国時代の須坂には、井上氏・高梨氏・須田氏などが国人領主として勢力を持ち",
    //     flg_samewindow: true
    //   },
    //   {
    //     id: "2501",
    //     date: "2019-01-21",
    //     title:
    //       "2019 信濃に勢力を伸ばしてきた武田信玄と、北信濃に強い力を持っていた越後の上杉謙信との戦いに巻き込まれて行きました。戦国時代の須坂には、井上氏・高梨氏・須田氏などが国人領主として勢力を持ち",
    //     flg_samewindow: true
    //   },
    //   {
    //     id: "2501",
    //     date: "2019-01-21",
    //     title:
    //       "2019 信濃に勢力を伸ばしてきた武田信玄と、北信濃に強い力を持っていた越後の上杉謙信との戦いに巻き込まれて行きました。戦国時代の須坂には、井上氏・高梨氏・須田氏などが国人領主として勢力を持ち",
    //     flg_samewindow: true
    //   },
    //   {
    //     id: "2501",
    //     date: "2019-01-21",
    //     title:
    //       "2019 信濃に勢力を伸ばしてきた武田信玄と、北信濃に強い力を持っていた越後の上杉謙信との戦いに巻き込まれて行きました。戦国時代の須坂には、井上氏・高梨氏・須田氏などが国人領主として勢力を持ち",
    //     flg_samewindow: true
    //   },
    //   {
    //     id: "2501",
    //     date: "2016-02-21",
    //     title:
    //       "2016 信濃に勢力を伸ばしてきた武田信玄と、北信濃に強い力を持っていた越後の上杉謙信との戦いに巻き込まれて行きました。戦国時代の須坂には、井上氏・高梨氏・須田氏などが国人領主として勢力を持ち",
    //     flg_samewindow: false
    //   },
    //   {
    //     id: "2501",
    //     date: "2016-02-21",
    //     title:
    //       "2016 信濃に勢力を伸ばしてきた武田信玄と、北信濃に強い力を持っていた越後の上杉謙信との戦いに巻き込まれて行きました。戦国時代の須坂には、井上氏・高梨氏・須田氏などが国人領主として勢力を持ち",
    //     flg_samewindow: false
    //   }
    // ];

    // 日付ごとにで配列に分ける
    let arr_newsData = {};
    let arr_year = [];
    for (let i = 0, li = News.pageData.length; i < li; i++) {
      let d = News.pageData[i].date;
      arr_newsData[d.substr(0, 4)] = [];
      arr_year.push(d.substr(0, 4));
    }
    for (var key in arr_newsData) {
      for (let i = 0, li = News.pageData.length; i < li; i++) {
        let d = News.pageData[i].date;
        if (key == d.substr(0, 4)) {
          arr_newsData[key].push(News.pageData[i]);
        }
      }
    }

    News.year = arr_newsData;
    News.latest = Math.max.apply(null, arr_year);
    News.current = News.latest;

    for (var key in arr_newsData) {
      let act = News.latest == key ? "is-active" : "";
      let ul =
        "" +
        '<ul class="news-blockList news-blockList-' +
        key +
        " " +
        act +
        '"></ul>' +
        "";
      $(".news-blockItems").append(ul);
      for (let i = 0, li = arr_newsData[key].length; i < li; i++) {
        let d = arr_newsData[key][i]["date"];
        let link =
          arr_newsData[key][i]["link"] != ""
            ? arr_newsData[key][i]["link"]
            : "/news/detail/?id=" + arr_newsData[key][i]["id"];
        let isBlank =
          arr_newsData[key][i]["flg_samewindow"] != true ? "_blank" : "_self";
        let news =
          "" +
          "<li>" +
          '<a class="is-defaultHover" href="' +
          link +
          '" target="' +
          isBlank +
          '">' +
          '<p class="news-itemDate">' +
          d.replace(/-/g, ".") +
          "</p>" +
          '<p class="news-itemBody">' +
          arr_newsData[key][i]["title"] +
          "</p>" +
          "</a>" +
          "</li>" +
          "";
        $(".news-blockList-" + key).append(news);
      }
    }
    News.setNewsTab();
  },
  setNewsTab: () => {
    let arr = [];
    for (var key in News.year) {
      arr.push(key);
    }
    function compareFunc(a, b) {
      return b - a;
    }
    arr.sort(compareFunc);
    for (let i = 0, li = arr.length; i < li; i++) {
      let act = News.latest == arr[i] ? "is-active" : "";
      let tab_pc =
        "" +
        '<li class="' +
        act +
        '">' +
        '<a href="javascript:void(0)" data-year="' +
        arr[i] +
        '">' +
        "<span>" +
        arr[i] +
        "年" +
        "</span>" +
        "</a>" +
        "</li>" +
        "";
      $(".news-tab-pc").append(tab_pc);
    }
  },
  clickEvt: () => {
    $("body").on("click", ".news-tab-pc li", function() {
      // let idx = $(".news-tab-pc li").index(this);
      $(".news-tab-pc li").removeClass("is-active");
      $(this).addClass("is-active");

      let dat = $(this)
        .find("a")
        .data("year");
      $(".news-blockList").removeClass("is-active");
      $(".news-blockList-" + dat).addClass("is-active");
      // $('select option:[value="2016"]').prop("selected", true);

      $(".news-tabActive a span").text(dat + "年");
      $(".news-tabInner").removeClass("is-active");
    });
    $(".news-tabActive a").on("click", function() {
      $(".news-tabInner").toggleClass("is-active");
    });
  }
};
