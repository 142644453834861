// import { TweenMax, TimelineMax } from "gsap";
// import { windowSize } from "./../_module/windowSize";
// import API_WEB from "./../_module/api";

export const Search = {
  /**
   * vals
   */
  isOpen: false,
  data: null,
  pages: 0,
  current: 1,
  items: 20,
  tresholdNum: 7,
  bool_1: "観光スポット",
  bool_2: "須坂市歴史的建造物",
  keys: {
    0: "area",
    1: "period",
    2: "field",
    // 3: "div_administrative",
    3: "div_designated",
    // 5: "city",
    4: "flg_touristspot",
    5: "flg_historicmonu",
    6: "word"
  },
  /**
   * funcs
   */
  init: () => {
    Search.drawer();
    Search.paramCheck();
    Search.click();
    Search.submit();
  },
  defaultTabState: () => {
    if (Search.isOpen === true) {
      $(".search-box").addClass("is-active");
      $(".search-terms").slideDown("slow");
    } else {
      $(".search-box").removeClass("is-active");
    }
  },
  drawer: () => {
    // sp 各見出し
    $(".search-checkTrigger").on("click", function() {
      let n = $(this).data("sec");
      $(".search-checkInner-" + n).toggleClass("is-active");
    });
    // 条件タブ
    $(".search-boxBtn").on("click", function() {
      $(".search-box").toggleClass("is-active");
      if (Search.isOpen) {
        $(".search-terms").slideUp("fast");
        Search.isOpen = false;
      } else {
        $(".search-terms").slideDown("slow");
        Search.isOpen = true;
      }
    });
  },
  toBoolean: data => {
    return data.toLowerCase() === "true";
  },
  paramCheck: () => {
    let param = [
      "period",
      "area",
      "field",
      // "div_administrative",
      "div_designated",
      // "city",
      "flg_touristspot",
      "flg_historicmonu",
      "word"
    ];
    let listAll = "false";
    let isOpened = false;
    let queryString = window.location.search;
    let queryObject = new Object();
    if (queryString) {
      queryString = queryString.substring(1);
      let parameters = queryString.split("&");
      for (let i = 0; i < parameters.length; i++) {
        let element = parameters[i].split("=");
        let paramName = decodeURIComponent(element[0]);
        let paramValue = decodeURIComponent(element[1]);
        queryObject[paramName] = paramValue;
      }

      for (let k of Object.keys(queryObject)) {
        if (k == "open") {
          isOpened = true;
          Search.isOpen = Search.toBoolean(queryObject[k]);
          Search.defaultTabState();
        }
        if (k == "all") {
          if (queryObject[k] == "true") {
            listAll = true;
          }
        }

        for (let i = 0; i < param.length; i++) {
          if (param[i] == k) {
            if (
              queryObject[k] != "" &&
              queryObject[k] != undefined &&
              queryObject[k] != null
            ) {
              if (queryObject[k] == "true") {
                if (k == "flg_touristspot") {
                  queryObject[k] = Search.bool_1;
                } else if (k == "flg_historicmonu") {
                  queryObject[k] = Search.bool_2;
                }
              }
              let multParam = queryObject[k].split(",");

              // console.log(multParam);
              // $("input[value = " + queryObject[k] + "]").prop("checked", true);
              for (let j = 0, li = multParam.length; j < li; j++) {
                $("input[value = " + multParam[j] + "]").prop("checked", true);
              }
            }
          }
        }
      }
      if (!isOpened) {
        if (listAll == "true") {
          Search.getAllItems();
        } else {
          // Search.getItems(decodeURIComponent(queryString));
          Search.getItems(queryString);
        }
      }
    }
  },
  submit: () => {
    document.getElementById("search_form").onkeypress = e => {
      // formに入力されたキーを取得
      const key = e.keyCode || e.charCode || 0;
      // 13はEnterキーのキーコード
      if (key == 13) {
        // アクションを行わない
        e.preventDefault();
        Search.startSearch();
      }
    };
  },
  click: () => {
    // 時代
    $("#period-all").on("change", function() {
      $("input[name=period]").prop("checked", this.checked);
    });
    // 地域
    $("#area-all").on("change", function() {
      $("input[name=area]").prop("checked", this.checked);
    });
    // 分野
    $("#field-all").on("change", function() {
      $("input[name=field]").prop("checked", this.checked);
    });
    // 国
    $("#land-all").on("change", function() {
      // $("input[name=land]").prop("checked", this.checked);
      // $(
      //   ".search-checkList-land > li > .search-checkInputBox > input[name=div_administrative]"
      // ).prop("checked", this.checked);
      $(
        ".search-checkList-land > li > .search-checkInputBox > input[name=div_designated]"
      ).prop("checked", this.checked);
    });

    // 県
    $("#pref-all").on("change", function() {
      // $("input[name=pref]").prop("checked", this.checked);
      // $(
      //   ".search-checkList-pref > li > .search-checkInputBox > input[name=div_administrative]"
      // ).prop("checked", this.checked);
      $(
        ".search-checkList-pref > li > .search-checkInputBox > input[name=div_designated]"
      ).prop("checked", this.checked);
    });

    // 市
    $("#city-all").on("change", function() {
      // $("input[name=city]").prop("checked", this.checked);
      // $(
      //   ".search-checkList-city > li > .search-checkInputBox > input[name=div_administrative]"
      // ).prop("checked", this.checked);
      $(
        ".search-checkList-city > li > .search-checkInputBox > input[name=div_designated]"
      ).prop("checked", this.checked);
    });

    $(".search-submit a").on("click", function() {
      Search.startSearch();
    });

    $(".searchBox-submit a").on("click", function() {
      Search.startSearch();
    });

    $(".search-resultPrev a").on("click", function() {
      event.preventDefault();
      let href = $(this).attr("href");
      jQuery("html,body")
        .stop()
        .animate(
          {
            scrollTop: $(href).offset().top
          },
          1200,
          "easeOutExpo"
        )
        .promise()
        .done(function() {
          Search.switchResult(0);
        });
    });

    $(".search-resultNext a").on("click", function() {
      event.preventDefault();
      let href = $(this).attr("href");
      jQuery("html,body")
        .stop()
        .animate(
          {
            scrollTop: $(href).offset().top
          },
          1200,
          "easeOutExpo"
        )
        .promise()
        .done(function() {
          Search.switchResult(1);
        });
    });

    $("body").on("click", "#search-resultBtnPagerList li a", function() {
      event.preventDefault();
      let data_page = $(this).data("page");
      let href = $(this).attr("href");
      jQuery("html,body")
        .stop()
        .animate(
          {
            scrollTop: $(href).offset().top
          },
          1200,
          "easeOutExpo"
        )
        .promise()
        .done(function() {
          Search.switchResult(null, data_page);
        });
    });
  },
  startSearch: () => {
    location.href = "/search/?" + Search.returnQuery();
  },
  getAllItems: () => {
    $("#l-loading").addClass("is-active");
    API_WEB.M2("cultural", 1, "").then(data => {
      setTimeout(() => {
        $("#l-loading").removeClass("is-active");
        $(".search-result").addClass("is-active");
        Search.data = data;
        Search.setResult();
      }, 200);
    });
  },
  getItems: query => {
    // let q = encodeURIComponent(query);
    let q = encodeURI(query);
    // let q = String(query);
    // console.log(query);
    $("#l-loading").addClass("is-active");
    API_WEB.M2("cultural", 2, q).then(data => {
      // console.log(data);
      setTimeout(() => {
        $("#l-loading").removeClass("is-active");
        $(".search-result").addClass("is-active");
        Search.data = data;
        Search.setResult();
      }, 200);
    });

    // テスト
    // $.getJSON("/data/search_data.json", json => {
    //   Search.data = json;
    //   Search.setResult();
    // });
  },
  setResult: () => {
    $("#search-resultNum").text(Search.data.length);
    Search.data.length == 0
      ? $(".search-resultFailure").addClass("is-active")
      : $(".search-resultFailure").removeClass("is-active");
    $(".search-resultListBlockItems").empty();
    $(".search-resultBtnBlock").removeClass("is-active");
    if (Search.data.length != 0) {
      if (Search.data.length <= Search.items) {
        //初期表示件数と同じかそれより少ないとき
        let ul =
          "" +
          '<ul class="m-search-resultList search-resultList resultList-0 is-active"></ul>' +
          "";
        $(".search-resultListBlockItems").append(ul);
        for (let i = 0, li = Search.data.length; i < li; i++) {
          let res =
            "" +
            "<li>" +
            '<a href="/search/item/?id=' +
            Search.data[i].id +
            '">' +
            '<div class="search-resultThumb">' +
            '<span style="background-image: url(' +
            Search.data[i].image +
            ');"></span>' +
            "</div>" +
            '<div class="search-resultTtl">' +
            "<p>" +
            "<span>" +
            Search.data[i].name +
            "</span>" +
            "</p>" +
            "</div>" +
            "</a>" +
            "</li>" +
            "";
          $(".resultList-0").append(res);
        }
      } else {
        $(".search-resultBtnBlock").addClass("is-active");
        let prog = 0;
        let rem = Search.data.length % Search.items;
        // console.log(rem); //15
        let div = Math.floor(Search.data.length / Search.items);
        // console.log(div); //1
        Search.pages = rem == 0 ? div : div + 1;
        Search.current = 1;

        Search.switchButton();
        Search.switchPager();

        // console.log(Search.data);

        for (let i = 0, li = div; i <= li; i++) {
          let len = i == div ? rem : Search.items;

          let ul =
            "" +
            '<ul class="m-search-resultList search-resultList resultList-' +
            i +
            (i == 0 ? " is-active" : "") +
            '"></ul>' +
            "";
          $(".search-resultListBlockItems").append(ul);

          // j=0,1    len= 20→15
          // for (let j = i * len, li = len + i * len; j < li; j++) {
          for (let j = prog, li = len + prog; j < li; j++) {
            let res =
              "" +
              "<li>" +
              '<a href="/search/item/?id=' +
              Search.data[j].id +
              '">' +
              '<div class="search-resultThumb">' +
              '<span style="background-image: url(' +
              Search.data[j].image +
              ');"></span>' +
              "</div>" +
              '<div class="search-resultTtl">' +
              "<p>" +
              "<span>" +
              Search.data[j].name +
              "</span>" +
              "</p>" +
              "</div>" +
              "</a>" +
              "</li>" +
              "";
            $(".resultList-" + i).append(res);
          }
          prog += len;
        }
      }
    }
  },
  switchResult: (int, data_p) => {
    $(".m-search-resultList").removeClass("is-active");
    if (int != null) {
      Search.current = int == 0 ? Search.current - 1 : Search.current + 1;
    } else {
      Search.current = data_p;
    }
    let listIdx = Search.current - 1;
    $(".resultList-" + listIdx).addClass("is-active");
    Search.switchButton();
    Search.switchPager();
  },
  switchButton: () => {
    // pagesは1以下にならない
    if (Search.pages == 2) {
      if (Search.current == 1) {
        $(".search-resultPrev").removeClass("is-active");
        $(".search-resultNext").addClass("is-active");
      }
      if (Search.current == 2) {
        $(".search-resultNext").removeClass("is-active");
        $(".search-resultPrev").addClass("is-active");
      }
    } else {
      $(".search-resultPrev").addClass("is-active");
      $(".search-resultNext").addClass("is-active");
      if (Search.current == 1) {
        $(".search-resultPrev").removeClass("is-active");
        $(".search-resultNext").addClass("is-active");
      }
      if (Search.current == Search.pages) {
        $(".search-resultPrev").addClass("is-active");
        $(".search-resultNext").removeClass("is-active");
      }
    }
  },
  switchPager: () => {
    // console.log(Search.pages); // 1~...
    // console.log(Search.current); // 1~...
    $("#search-resultBtnPagerList").empty();
    if (Search.tresholdNum <= Search.pages) {
      $("#search-resultBtnPagerList li").removeClass("is-active");
      // 1 2 3 ... 7(x) ↓
      if (Search.current < 4) {
        for (let i = 1, li = 6; i <= li; i++) {
          let pagerLi = "";
          if (i == li - 1) {
            pagerLi =
              "" +
              "<li>" +
              '<div class="search-resultBtnPagerDott">・・・</div>' +
              "</li>" +
              "";
          } else {
            pagerLi =
              "" +
              "<li>" +
              '<a href="#result" data-page="' +
              (i == li ? Search.pages : i) +
              '">' +
              "<span>" +
              (i == li ? Search.pages : i) +
              "</span>" +
              "</a>" +
              "</li>" +
              "";
          }
          $("#search-resultBtnPagerList").append(pagerLi);
        }
        $("#search-resultBtnPagerList li")
          .eq(Search.current - 1)
          .addClass("is-active");
      }
      // --------
      // 1 ... 3 4 5 ... 7(X)
      // --------
      else if (Search.current >= 4 && Search.current < Search.pages - 2) {
        for (let i = 1, li = 7; i <= li; i++) {
          let pagerLi = "";
          if (i == 1) {
            pagerLi =
              "" +
              "<li>" +
              '<a href="#result" data-page="' +
              i +
              '">' +
              "<span>" +
              i +
              "</span>" +
              "</a>" +
              "</li>" +
              "";
          } else if (i == 2 || i == li - 1) {
            pagerLi =
              "" +
              "<li>" +
              '<div class="search-resultBtnPagerDott">・・・</div>' +
              "</li>" +
              "";
          } else {
            // 3 4 5
            if (i >= 3 && i < 6) {
              pagerLi =
                "" +
                "<li>" +
                '<a href="#result" data-page="' +
                (Search.current + i - 4) +
                // (i == li ? Search.pages : i) +
                '">' +
                "<span>" +
                (Search.current + i - 4) +
                "</span>" +
                "</a>" +
                "</li>" +
                "";
            } else if (i == li) {
              pagerLi =
                "" +
                "<li>" +
                '<a href="#result" data-page="' +
                Search.pages +
                '">' +
                "<span>" +
                Search.pages +
                "</span>" +
                "</a>" +
                "</li>" +
                "";
            }
          }
          $("#search-resultBtnPagerList").append(pagerLi);
        }
        $("#search-resultBtnPagerList li")
          .eq(3)
          .addClass("is-active");
      }
      // 1 ... 5 6 7(X)
      // --------
      else if (Search.current >= Search.pages - 2) {
        for (let i = 1, li = 6; i <= li; i++) {
          let pagerLi = "";
          if (i == 2) {
            pagerLi =
              "" +
              "<li>" +
              '<div class="search-resultBtnPagerDott">・・・</div>' +
              "</li>" +
              "";
          } else {
            if (i == 1) {
              pagerLi =
                "" +
                "<li>" +
                '<a href="#result" data-page="' +
                i +
                '">' +
                "<span>" +
                i +
                "</span>" +
                "</a>" +
                "</li>" +
                "";
            }
            // 3~
            else if (i >= 3) {
              pagerLi =
                "" +
                '<li class="' +
                (Search.pages + i - 6 == Search.current ? "is-active" : "") +
                '">' +
                '<a href="#result" data-page="' +
                (Search.pages + i - 6) +
                '">' +
                "<span>" +
                (Search.pages + i - 6) +
                "</span>" +
                "</a>" +
                "</li>" +
                "";
            }
          }
          $("#search-resultBtnPagerList").append(pagerLi);
        }
      }
    } else {
      // 1~6まで表示
      for (let i = 1, li = Search.pages; i <= li; i++) {
        let pagerLi =
          "" +
          "<li>" +
          '<a href="#result" data-page="' +
          i +
          '">' +
          "<span>" +
          i +
          "</span>" +
          "</a>" +
          "</li>" +
          "";
        $("#search-resultBtnPagerList").append(pagerLi);
      }

      $("#search-resultBtnPagerList li").removeClass("is-active");
      $("#search-resultBtnPagerList li")
        .eq(Search.current - 1)
        .addClass("is-active");
    }
  },
  returnQuery: () => {
    let arr = {
      area: [],
      period: [],
      field: [],
      // div_administrative: [],
      div_designated: [],
      // city: [],
      flg_touristspot: [],
      flg_historicmonu: []
      // word: []
    };

    const area = document.form.area;
    const period = document.form.period;
    const field = document.form.field;
    // const land = document.form.land;
    // const pref = document.form.pref;
    // const administrative = document.form.div_administrative;
    const designated = document.form.div_designated;
    // const city = document.form.city;
    const flg_touristspot = [document.form.flg_touristspot];
    const flg_historicmonu = [document.form.flg_historicmonu];
    // const word = document.form.search.value;
    const word = document.getElementById("input_msg").value;

    let l = [
      area,
      period,
      field,
      // administrative,
      designated,
      // city,
      flg_touristspot,
      flg_historicmonu
      // word
    ];

    let query = "";
    for (let i = 0, li = l.length; i < li; i++) {
      for (let j = 0, li_ = l[i].length; j < li_; j++) {
        if (l[i][j].checked) {
          let pu =
            Search.keys[i] == "flg_touristspot" ||
            Search.keys[i] == "flg_historicmonu"
              ? true
              : l[i][j].value;
          arr[Search.keys[i]].push(pu);
        }
      }
      // console.log(arr);
      if (arr[Search.keys[i]].length != 0) {
        // console.log(arr[Search.keys[i]]);
        let con = query != "" ? "&" : "";
        let str = arr[Search.keys[i]].join(",");
        let res = con + Search.keys[i] + "=" + str;
        query += res;
      }
    }

    if (word != "" && word != undefined && word != null) {
      const c1 = word.split(/\s/);
      let con = query != "" ? "&" : "";
      let res = con + "word=" + c1;
      query += res;
    }

    if (query == "") {
      query = "all=true";
    }
    return query;
  },
  resize: () => {
    let setTimeoutId = null;
    window.addEventListener("resize", e => {
      if (setTimeoutId) clearTimeout(setTimeoutId);
      setTimeoutId = setTimeout(() => {
        // Search.setSlick();
        setTimeoutId = null;
      }, 1000);
    });
  }
};
