// import { TimelineMax } from "gsap";
// import API_WEB from "./../_module/api";
// import { windowSize } from "./../_module/windowSize";
// import { easeInOutExpo, easeOutExpo, easeInOutCirc, easeInOutQuart, easeOutQuart, easeInOutBack, easeOutBack } from 'easing-utils';

export const Taiken = {
  /**
   * vals
   */
  DATA: "",
  DATA_LENGTH: 0,
  length: 0,
  add: 4,
  current: 0,
  /**
   * funcs
   */
  init: () => {
    Taiken.getTaikenData();
    Taiken.more();
  },
  getTaikenData: () => {
    API_WEB.M2("taiken", 1, "").then(data => {
      Taiken.DATA = data.reverse();
      Taiken.DATA_LENGTH = data.length;
      Taiken.length = Taiken.DATA.length > 13 ? 13 : Taiken.DATA.length;
      if (Taiken.DATA_LENGTH <= 13) {
        Taiken.moreOff();
      }
      Taiken.defTiken();
    });
  },
  defTiken: () => {
    Taiken.setTiken();
  },
  setTiken: () => {
    for (let i = 0, li = Taiken.DATA_LENGTH; i < li; i++) {
      let l = "";
      if (i == 0) {
        // 体験記が増えたら l-large に
        l = "l-large";
        // l = "";
      }
      let taiken =
        "" +
        '<li class="' +
        l +
        '">' +
        '<a href="/taiken/detail/?id=' +
        Taiken.DATA[i].id +
        "&num=" +
        // (i + 1) +
        Taiken.DATA[i].index +
        // (Taiken.DATA_LENGTH - i) +
        '">' +
        '<div class="m-number-label">' +
        '<div class="number-labelInner">' +
        "<p>" +
        "#" +
        "<span>" +
        // (i + 1) +
        Taiken.DATA[i].index +
        // (Taiken.DATA_LENGTH - i) +
        "</span>" +
        "</p>" +
        "</div>" +
        '<div class="number-labelBg"></div>' +
        "</div>" +
        '<div class="section-record-itemThumb">' +
        '<span style="background-image: url(' +
        Taiken.DATA[i].image +
        ');"></span>' +
        "</div>" +
        '<div class="section-record-itemTxt section-item-ttl">' +
        "<span>" +
        Taiken.DATA[i].name +
        "</span>" +
        '<div class="m-arrCirc"><div class="arrCirc-ico"></div></div>' +
        "</div>" +
        '<div class="m-txtBg bottom-txtBg"></div>' +
        "</a>" +
        "</li>" +
        "";
      $(".section-record-list").append(taiken);
      // $(taiken).prependTo(".section-record-list");
    }
    $(".section-record-list").each(function() {
      $(this)
        .find("li:not(:lt(" + Taiken.length + "))")
        .hide();
      Taiken.current = Taiken.length;
    });
  },
  stateUpdate: () => {},
  more: () => {
    $(".section-record-more a").on("click", function(event) {
      Taiken.current += Taiken.add;
      $(".section-record-list")
        .find("li:lt(" + Taiken.current + ")")
        .slideDown("slow");
      if (Taiken.current >= Taiken.DATA_LENGTH) {
        Taiken.moreOff();
      }
    });
  },
  moreOff: () => {
    $(".section-record-more").remove();
  }
};
