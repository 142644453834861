// import { TimelineMax } from "gsap";
// import { windowSize } from "./../_module/windowSize";

export const Publishing = {
  /**
   * funcs
   */
  init: () => {
    Publishing.setPublishing();
  },
  setPublishing: () => {
    API_WEB.M2("publish", 1, "").then(data => {
      // console.log(data);
      // console.log(data.length);
      for (let i = 0, li = data.length; i < li; i++) {
        let publishing =
          "" +
          "<li>" +
          '<a href="/publishing/detail/?id=' +
          data[i]["id"] +
          '">' +
          '<div class="publishing-listThumb">' +
          '<span style="background-image: url(' +
          data[i]["image"] +
          ');"></span>' +
          "</div>" +
          '<div class="publishing-listTtl">' +
          "<p>" +
          "<span>" +
          data[i]["title"] +
          "</span>" +
          "</p>" +
          "</div>" +
          "</a>" +
          "</li>" +
          "";
        $(".publishing-list ul").append(publishing);
      }
    });
  }
};
