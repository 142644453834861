// import { TimelineMax } from "gsap";
// import API_WEB from "./../_module/api";
import { LowPageScroll } from "./../_module/lowpagescroll";
// import { easeInOutExpo, easeOutExpo, easeInOutCirc, easeInOutQuart, easeOutQuart, easeInOutBack, easeOutBack } from 'easing-utils';

export const History_low = {
  /**
   * vals
   */
  // slickBlock: $(".m-slickList"),
  // isSlickActive: $(".m-slickList.slick-initialized"),
  /**
   * funcs
   */
  init: () => {
    LowPageScroll.init();
  }
};
