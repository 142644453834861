// import { TimelineMax } from "gsap";
// import { windowSize } from "./../_module/windowSize";
// import { vert, frag } from "./../_shader/bg_2";
// import { easeInOutExpo, easeOutExpo, easeInOutCirc, easeInOutQuart, easeOutQuart, easeInOutBack, easeOutBack } from 'easing-utils';
// import * as THREE from "three/build/three";

// import slick from "slick-carousel";

export const Museum = {
  /**
   * funcs
   */
  init: () => {
    // Museum.setSlick();
    // Museum.resize();
    // console.log("dededeed");
  }
  // setSlick: () => {
  //   if (windowSize.device()) {
  //     $(".m-slickList.slick-initialized").slick("unslick");
  //   } else {
  //     $(".m-slickList")
  //       .not(".slick-initialized")
  //       .slick({
  //         dots: false,
  //         infinite: false,
  //         arrows: false,
  //         slidesToShow: 1,
  //         autoplay: false,
  //         speed: 650,
  //         centerMode: true,
  //         centerPadding: "20%",
  //         cssEase: "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
  //         draggable: true
  //       })
  //       .on("beforeChange", function(event, slick, currentSlide, nextSlide) {})
  //       .on("afterChange", function(event, slick, currentSlide) {});
  //   }
  // }
  // resize: () => {
  //   let setTimeoutId = null;
  //   window.addEventListener("resize", e => {
  //     if (setTimeoutId) clearTimeout(setTimeoutId);
  //     setTimeoutId = setTimeout(() => {
  //       Museum.setSlick();
  //       setTimeoutId = null;
  //     }, 1000);
  //   });
  // }
};
