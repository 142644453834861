export const ua = () => {
  let ua = (
    function(u) {
      return {
        IE_or_EDGE:(u.indexOf('msie') != -1 || u.indexOf('trident') != -1)
          || u.indexOf('edge') != -1,
        Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1)
          || u.indexOf("ipad") != -1
          || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
          || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
          || u.indexOf("kindle") != -1
          || u.indexOf("silk") != -1
          || u.indexOf("playbook") != -1,
        Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
          || u.indexOf("iphone") != -1
          || u.indexOf("ipod") != -1
          || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
          || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
          || u.indexOf("blackberry") != -1
      }
    }
  )(window.navigator.userAgent.toLowerCase());

  if (ua.IE_or_EDGE){
    return "IE_EDGE";
  }
  else if (ua.Tablet==true || ua.Mobile==true){
    return "MOBILE";
    // return "IE_EDGE";
  }
  else {
    return "PC";
    // return "IE_EDGE";
  }
}
