export const Ytb_Ready = {
  num: 0,
  // player: null,
  player: [],
  // videoId: "",
  videoId: [],
  time: null,
  start: 0,
  isPlay: false,
  init: data => {
    Ytb_Ready.num = data.length;
    for (let i = 0, li = data.length; i < li; i++) {
      Ytb_Ready.videoId.push(data[i]);
    }
    Ytb_Ready.set();
  },
  set: () => {
    for (let i = 0, li = Ytb_Ready.num; i < li; i++) {
      Ytb_Ready.player[i] = new YT.Player(Ytb_Ready.videoId[i], {
        height: "100%",
        width: "100%",
        playerVars: {
          autoplay: 0,
          playsinline: 1,
          controls: 1,
          loop: 0,
          rel: 0,
          start: 2 // 秒
          // showinfo:0,
        },
        // videoId: "Ytb_Ready.videoId",
        videoId: Ytb_Ready.videoId[i],
        events: {
          onReady: Ytb_Ready.onPlayerReady,
          onStateChange: Ytb_Ready.onPlayerStateChange
        }
      });
    }
  },
  onPlayerReady: () => {
    // Ytb_Ready.player.mute();
  },
  onPlayerStateChange: event => {
    let ytStatus = event.data;

    if (ytStatus === YT.PlayerState.ENDED) {
      // 動画を先頭まで戻して、サムネを表示
      // const tl = gsap
      //   .timeline()
      //   .add(() => {
      //     event.target.seekTo(0);
      //     event.target.stopVideo();
      //   })
      //   .to(trigger, 0.1, {
      //     autoAlpha: 1
      //   });
    }
  }
};
