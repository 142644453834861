export const global = {
  root: "/",
  page: "",
  // onSpHeaderMenu: false
  onTopMvArea: true
  // menu
  // isMenu:true,
  // isSceneTrans:false,
  // // glb
  // currentID:0,
  // sceneIDs:[],
  // nextScene:null,
  // direction:null,
  // contentAreaHeight:0,
  // freeScrollPos:0,
  // backToFixedArea:true,
  // // whale
  // // whaleSceneCurrentID:0,
  // // content
  // // inContentArea:false,
  // drawerActive: true,
  // historyItemHide: ".history-itemHide",
  // isTransition:true,

  // anchor_item: $('.header-nav > ul > li'),
};
