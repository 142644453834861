export const scrollHandle={
  noScroll: () => {
    // PCでのスクロール禁止
    document.addEventListener("mousewheel", scrollHandle.scrollControl, { passive: false });
    // スマホでのタッチ操作でのスクロール禁止
    document.addEventListener("touchmove", scrollHandle.scrollControl, { passive: false });
  },
  returnScroll: () => {
    // PCでのスクロール禁止解除
    document.removeEventListener("mousewheel", scrollHandle.scrollControl, { passive: false });
    // スマホでのタッチ操作でのスクロール禁止解除
    document.removeEventListener('touchmove', scrollHandle.scrollControl, { passive: false });
  },
  scrollControl: (e) => {
    e.preventDefault();
  },
};