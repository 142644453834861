// import { TimelineMax } from "gsap";
// import { windowSize } from "./../_module/windowSize";
import { LowPageScroll } from "./../_module/lowpagescroll";
// import { vert, frag } from "./../_shader/bg_2";
// import { easeInOutExpo, easeOutExpo, easeInOutCirc, easeInOutQuart, easeOutQuart, easeInOutBack, easeOutBack } from 'easing-utils';
// import * as THREE from "three/build/three";

// import slick from "slick-carousel";

export const Museum_low = {
  /**
   * vals
   */

  /**
   * funcs
   */
  init: () => {
    LowPageScroll.init();
    // API_WEB.M3("cultural", 1, "").then(data => {
    //   console.log(data);
    // });
  }
};
