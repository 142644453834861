// import { TweenMax } from 'gsap';
import { global } from "./global";

import { scrollHandle } from "../_module/scrollHandle";

export const menu = {
  header: $("#l-header"),
  // headerMenu:$('.header-menu-list ul li'),
  headerMenu: $(".header-menu"),
  headerSpMenuBg: $(".header-spMenuBg"),
  trigger: $(".header-trigger"),
  active: "is-active",
  // currentSection: 0,
  init: () => {
    menu.menuSP();
  },
  // currentSection: idx => {
  //   // global.anchor_item.removeClass(menu.active);
  //   // global.anchor_item.eq(idx).addClass(menu.active);
  // },
  menuSP: () => {
    menu.trigger.on("click", () => {
      if (menu.trigger.hasClass(menu.active)) {
        scrollHandle.returnScroll();
      } else {
        scrollHandle.noScroll();
      }
      menu.trigger.toggleClass(menu.active);
      menu.headerMenu.toggleClass(menu.active);
      if (global.page == "Top") {
        menu.headerSpMenuBg.toggleClass(menu.active);
        // if (menu.header.hasClass(menu.active) == false) {
        //   if(global.onTopMvArea){
        //     menu.header.addClass(menu.active);
        //   }
        // }

        // if (menu.header.hasClass(menu.active) == false) {
        //   menu.header.addClass(menu.active);
        //   // global.onSpHeaderMenu=true;
        // }
      }
    });
  }
};
