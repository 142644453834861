// import { TimelineMax } from "gsap";
// import { windowSize } from "./../_module/windowSize";
// import { vert, frag } from "./../_shader/bg_2";
// import { easeInOutExpo, easeOutExpo, easeInOutCirc, easeInOutQuart, easeOutQuart, easeInOutBack, easeOutBack } from 'easing-utils';
// import * as THREE from "three/build/three";

// import slick from "slick-carousel";
import ImageMap from "image-map";

export const Search_type = {
  isOpen: false,
  /**
   * funcs
   */
  init: () => {
    // Search.drawer();
    // Search.paramCheck();
    // console.log(ImageMap);
    setTimeout(() => {
      ImageMap("img[usemap]");
    }, 400);

    Search_type.resize();
  },
  resize: () => {
    let setTimeoutId = null;
    window.addEventListener("resize", e => {
      if (setTimeoutId) clearTimeout(setTimeoutId);
      setTimeoutId = setTimeout(() => {
        ImageMap("img[usemap]");
        setTimeoutId = null;
      }, 1000);
    });
  }
};
