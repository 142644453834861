// import { TweenMax } from 'gsap';

export const meta = {
  init: data => {
    let title = data["title"].replace("<br>", "");
    let description = data["title"].replace("<br>", "");
    document.title = title;
    $("meta[property ='og:title']").attr("content", title);
    $("meta[name ='description']").attr("content", description);
    $("meta[property ='og:description']").attr("content", description);
  }
};
