import { TweenMax } from "gsap";
import { meta } from "../_module/meta";
import "./../_module/loadYoutubeResource";
import { Ytb_Ready } from "./../_module/enbedYoutube";

import { scrollHandle } from "../_module/scrollHandle";

export const Search_detail = {
  /**
   * vals
   */
  id: "",
  num: "",
  pageData: "",
  relArray: [],
  rel: "",
  /**
   * funcs
   */
  init: () => {
    // Search_detail.modal();
    Search_detail.click();
    Search_detail.paramCheck();
    // API_WEB.M3("cultural", 1, "").then(data => {
    //   console.log()
    // });
    // let u = "http://www.youtube.com/watch?v=2hJE9TB8HVA";
    // let c = u.match(/youtube.com/);
    // if (c != null) {
    //   //strにhogeを含む場合の処理
    //   console.log(c.input);
    // }

    // // var url = "...";
    // var videoid = u.match(
    //   /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
    // );
    // if (videoid != null) {
    //   console.log("video id = ", videoid[1]);
    // } else {
    //   console.log("The youtube url is not valid.");
    // }
  },
  getData: () => {
    // let id = encodeURIComponent("id=" + Search_detail.id);
    let id = "id=" + Search_detail.id;
    API_WEB.M2("cultural", 3, id).then(data => {
      if (data == "" || data.length == 0) {
        Search_detail.toSearch();
      }
      Search_detail.pageData = data[0];
      Search_detail.setPage();
      // console.log(Search_detail.pageData);
      $(".search-resultBottomBlock").fadeIn(400);
      $(".result-detail").fadeIn(400);
      $("#l-footer").fadeIn(400);

      let query = "field=" + Search_detail.pageData["field"];

      $("#l-loading").addClass("is-active");

      // let q = encodeURIComponent(query);
      let q = encodeURI(query);
      API_WEB.M2("cultural", 2, q).then(data_ => {
        $("#l-loading").removeClass("is-active");
        Search_detail.rel = data_;
        Search_detail.setRelation();
      });
    });

    // テスト
    // $.getJSON("/data/search_item.json", data => {
    //   Search_detail.pageData = data[0];
    //   Search_detail.setPage();
    // });
  },
  setRelation: () => {
    if (Search_detail.rel.length != 0) {
      let len = Search_detail.rel.length >= 20 ? 20 : Search_detail.rel.length;
      for (let i = 0, li = Search_detail.rel.length; i < li; i++) {
        if (Search_detail.id != Search_detail.rel[i].id) {
          Search_detail.relArray.push(Search_detail.rel[i]);
        }
      }
      for (let i = 0, li = len; i < li; i++) {
        let res =
          "" +
          "<li>" +
          '<a href="/search/item/?id=' +
          Search_detail.rel[i].id +
          '">' +
          '<div class="search-resultThumb">' +
          '<span style="background-image: url(' +
          Search_detail.rel[i].image +
          ');"></span>' +
          "</div>" +
          '<div class="search-resultTtl">' +
          "<p>" +
          "<span>" +
          Search_detail.rel[i].name +
          "</span>" +
          "</p>" +
          "</div>" +
          "</a>" +
          "</li>" +
          "";
        $(".m-search-resultList").append(res);
      }
    } else {
      $(".search-resultListBlockInner").remove();
    }
  },
  setPage: () => {
    let m = {
      title: Search_detail.pageData["name"] + " | 文化財を探す",
      desc: Search_detail.pageData["desc"]
    };
    meta.init(m);

    $(".footer-pan li")
      .last()
      .find("a")
      .html(Search_detail.pageData["name"]);

    // タイトル sns 関連タグ
    for (var key in Search_detail.pageData) {
      if (Search_detail.pageData[key] != "") {
        $(".set-top-" + key).html(Search_detail.pageData[key]);
        $(".set-link-" + key + " a").attr("href", Search_detail.pageData[key]);
        $(".set-rel-" + key).html(Search_detail.pageData[key]);
        $(".set-lead-" + key).html(Search_detail.pageData[key]);
        $(".set-reco-" + key).attr("href", Search_detail.pageData[key]);
        $(".set-add-" + key).html(Search_detail.pageData[key]);
      } else {
        $(".set-top-" + key).remove();
        $(".set-link-" + key).remove();
        $(".set-rel-" + key).remove();
        $(".set-relBox-" + key).remove();
        $(".set-lead-" + key).remove();
        $(".set-secReco-" + key).remove();
        $(".set-secAdd-" + key).remove();
      }
      if (key == "flg_historicmonu" || key == "flg_touristspot") {
        if (Search_detail.pageData[key] != true) {
          $(".set-bool-" + key).remove();
        }
      }

      if (key == "cont") {
        let keyCap =
          Search_detail.pageData[key][0]["text"] != ""
            ? "<p>" + Search_detail.pageData[key][0]["text"] + "</p>"
            : "";
        let keyImage =
          "" +
          '<div class="result-detailMv">' +
          '<span style="background-image: url(' +
          Search_detail.pageData[key][0]["image"] +
          ');"></span>' +
          "</div>" +
          keyCap +
          "";
        $(".result-detailMvBlock").append(keyImage);
        for (let i = 0, li = Search_detail.pageData[key].length; i < li; i++) {
          let cap =
            Search_detail.pageData[key][i]["text"] != ""
              ? "<p>" + Search_detail.pageData[key][i]["text"] + "</p>"
              : "";
          let thumbList =
            "" +
            "<li>" +
            '<a href="javascript:void(0);" data-img="' +
            i +
            '">' +
            '<div class="result-detailThumbItem">' +
            '<span style="background-image: url(' +
            Search_detail.pageData[key][i]["image"] +
            ');"></span>' +
            "</div>" +
            cap +
            "</a>" +
            "</li>" +
            "";
          $(".result-detailThumbList ul").append(thumbList);

          let modalItem =
            "" +
            '<div class="slider-modalItem">' +
            '<a href="javascript:void(0);">' +
            '<div class="slider-modalImg">' +
            '<span style="background-image: url(' +
            Search_detail.pageData[key][i]["image"] +
            ');"></span>' +
            // '<img src="' +
            // Search_detail.pageData[key][i]["image"] +
            // '">' +
            "<p>" +
            cap +
            "</p>" +
            "</div>" +
            "</a>" +
            "</div>" +
            "";
          $("#m-slickModal").append(modalItem);
        }

        Search_detail.modal();
      }

      if (key == "relation") {
        let videoIdArray = [];
        if (
          Search_detail.pageData[key].length != 0 ||
          Search_detail.pageData[key] != ""
        ) {
          for (
            let i = 0, li = Search_detail.pageData[key].length;
            i < li;
            i++
          ) {
            let _u = Search_detail.pageData[key][i]["url"];
            let check = _u.match(/youtube.com/);
            if (check != null) {
              let _videoid = _u.match(
                /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
              );
              videoIdArray.push(_videoid[1]);
            } else {
              let infoList =
                "" +
                "<li>" +
                '<a href="' +
                Search_detail.pageData[key][i]["url"] +
                '"' +
                ' target="_blank">' +
                '<p class="infoList-txt">' +
                "<span>" +
                Search_detail.pageData[key][i]["text"] +
                "</span>" +
                "</p>" +
                "</a>" +
                "</li>" +
                "";
              $(".search-infoList .m-infoList").append(infoList);
            }
          }

          // 動画
          if (videoIdArray.length != 0) {
            for (let j = 0, _li = videoIdArray.length; j < _li; j++) {
              let videoBlock =
                "" +
                '<div class="search-resultInfoMovieInner">' +
                '<div id="' +
                videoIdArray[j] +
                '">' +
                "</div>" +
                "";
              $(".search-resultInfoMovie").append(videoBlock);
            }
            Ytb_Ready.init(videoIdArray);
          } else {
            $(".search-resultInfoMovie").remove();
          }
        } else {
          $(".search-resultInfoBlockInner").remove();
        }
      }
    }

    if (
      Search_detail.pageData["latitude"] != "" &&
      Search_detail.pageData["longitude"] != ""
    ) {
      // map
      // let ll = "q=36.6567823,138.3042544";
      let ll =
        "q=" +
        Search_detail.pageData["latitude"] +
        "," +
        Search_detail.pageData["longitude"];
      // let src_ = $(".result-detailCourseMap > iframe").attr("src");
      let iframe =
        "" +
        '<iframe src="https://maps.google.co.jp/maps?output=embed&t=m&' +
        ll +
        '"' +
        " width='600' height='450' frameborder='0' style='border:0' aria-hidden='false' tabindex='0' allowfullscreen=''" +
        ">" +
        "";

      $(".result-detailCourseMap").append(iframe);
      // $(".result-detailCourseMap > iframe").attr("src", src_ + ll);
    } else {
      $(".result-detailCourseMap").remove();
    }
  },
  paramCheck: () => {
    let isID = false;
    let queryString = window.location.search;
    let queryObject = new Object();
    if (queryString) {
      queryString = queryString.substring(1);
      let parameters = queryString.split("&");
      for (let i = 0; i < parameters.length; i++) {
        let element = parameters[i].split("=");
        let paramName = decodeURIComponent(element[0]);
        let paramValue = decodeURIComponent(element[1]);
        queryObject[paramName] = paramValue;
      }
      for (let k of Object.keys(queryObject)) {
        if (k == "id") {
          if (
            queryObject[k] != "" &&
            queryObject[k] != undefined &&
            queryObject[k] != null
          ) {
            isID = true;
            Search_detail.id = queryObject[k];
          } else {
            Search_detail.toSearch();
          }
        }
        if (k == "num") {
          Search_detail.num = queryObject[k];
        }
      }
      if (isID == true) {
        Search_detail.getData();
      } else {
        Search_detail.toSearch();
      }
    }
  },
  toSearch: () => {
    location.href = "/search/";
  },
  modal: () => {
    $("#m-slickModal")
      .not(".slick-initialized")
      .slick({
        dots: false,
        arrows: true,
        slidesToShow: 1,
        autoplay: false,
        speed: 650,
        centerMode: true,
        centerPadding: "0%",
        cssEase: "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
        draggable: true
      });
  },
  click: () => {
    let $modal = $("#m-slider-modal");
    $(".slider-modalClose,.slider-modalBg").on("click", function() {
      TweenMax.to($modal, { autoAlpha: 0 });
      scrollHandle.returnScroll();
    });
    $("body").on("click", ".result-detailThumbList ul li a", function() {
      scrollHandle.noScroll();
      let n = $(this).data("img");
      $("#m-slickModal").slick("slickGoTo", n, true);
      TweenMax.to($modal, { autoAlpha: 1 });
    });
  },
  resize: () => {
    // let setTimeoutId = null;
    // window.addEventListener("resize", e => {
    //   if (setTimeoutId) clearTimeout(setTimeoutId);
    //   setTimeoutId = setTimeout(() => {
    //     // Search.setSlick();
    //     setTimeoutId = null;
    //   }, 1000);
    // });
  }
};
