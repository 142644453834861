import { meta } from "../_module/meta";

export const Publishing_low = {
  /**
   * vals
   */
  id: "",
  pageData: "",
  /**
   * funcs
   */
  init: () => {
    Publishing_low.paramCheck();
  },
  paramCheck: () => {
    let queryString = window.location.search;
    let queryObject = new Object();
    if (queryString) {
      queryString = queryString.substring(1);
      let parameters = queryString.split("&");
      for (let i = 0; i < parameters.length; i++) {
        let element = parameters[i].split("=");
        let paramName = decodeURIComponent(element[0]);
        let paramValue = decodeURIComponent(element[1]);
        queryObject[paramName] = paramValue;
      }
      for (let k of Object.keys(queryObject)) {
        if (k == "id") {
          if (
            queryObject[k] != "" &&
            queryObject[k] != undefined &&
            queryObject[k] != null
          ) {
            Publishing_low.id = queryObject[k];
            Publishing_low.getData();
          } else {
            Publishing_low.toPublishing();
          }
        } else {
          Publishing_low.toPublishing();
        }
      }
    }
  },
  toPublishing: () => {
    location.href = "/publishing/";
  },
  setPage: () => {
    // console.log(Publishing_low.pageData);
    meta.init(Publishing_low.pageData);

    $(".footer-pan li")
      .last()
      .find("a")
      .html(Publishing_low.pageData["title"]);

    for (var key in Publishing_low.pageData) {
      if (key == "image") {
        $(".set-image").attr("src", Publishing_low.pageData[key]);
      } else {
        if (Publishing_low.pageData[key] == "") {
          $(".set-" + key).remove();
          $(".set-lead-" + key).remove();
        } else {
          $(".set-" + key).html(Publishing_low.pageData[key]);
        }
      }
    }
  },
  getData: () => {
    let isID = false;
    API_WEB.M2("publish", 1, "").then(data => {
      for (let i = 0, li = data.length; i < li; i++) {
        if (data[i].id == Publishing_low.id) {
          isID = true;
          Publishing_low.pageData = data[i];
          Publishing_low.setPage();
          break;
        }
      }
      if (isID == false) {
        Publishing_low.toPublishing();
      }
    });
  }
};
